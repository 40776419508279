<template>
  <div class="max-w-md lg:max-w-5xl mx-auto">
    <div class="form-wrap">
      <div class="lg:flex">
        <div class="max-w-full text-left flex flex-wrap items-center lg:w-1/2">
          <div class="flex items-baseline w-full text-sm mb-2 pl-5">
            <h2 class="text-left text-xl font-bold text-dark mr-4">
              {{ $t("card.list.add.available", [leftCount]) }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvailableCardCount',
  computed: {
    leftCount () {
      return this.$store.getters['CARD/LEFT_COUNT']
    }
  }
}
</script>

<style lang="scss" scoped>
.form-wrap {
  @apply bg-[#F4F4F4] rounded-2xl lg:rounded-[32px] py-[30px] px-5 lg:px-10;
}
</style>
